.App {
  font-family: sans-serif;
  text-align: center;
  margin: 0;
  padding: 0;
}


html {
  margin: 0;
  padding: 0;
}
